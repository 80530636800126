import React from 'react'
import styled from 'styled-components'
import HomeNavBar from '../components/NavBar/HomeNavBar';
import GlobalHelmet from '../components/GlobalHelmet';
import Footer from '../components/Footer';
import { graphql } from 'gatsby';
import { MDXRenderer } from "gatsby-mdx"

const PaddedFooter = styled(Footer)`
  margin: 0 auto;
  padding: 40px 60px 40px 60px;
`;

const LandingPage =  ({ className, data }) => {
  return (
    <div className={className}>
      <GlobalHelmet/>
      <HomeNavBar isHeroTitle isHomePage/>
      <MDXRenderer>{data.mdx.code.body}</MDXRenderer>
      <PaddedFooter/>
    </div>
  )
}


export default styled(LandingPage)`
  ${HomeNavBar} {
    padding-left: 60px;
    padding-right: 60px;
    @media screen and (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .title {
    font-size: 50px;
    line-height: 80px;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-overflow: wrap;
  }
`

export const query = graphql`
  query {
    mdx(frontmatter: {id: {eq: "home/index"}}) {
      code {
        body
      }
    }
  }
`